import moment from 'moment';

export function getDateStringFromTimestamp(timestamp, pattern = 'YYYY-MM-DD, HH:mm') {
  if (timestamp === null) {
    return '';
  } else {
    return moment(new Date(timestamp.seconds * 1000)).format(pattern);
  }
}

export function getDateStringFromDate(date, pattern = 'YYYY-MM-DD') {
	if (date === null) {
		return '';
	} else {
		return moment(date).format(pattern);
	}
}

export function getDateString(dateString = '', pattern = 'YYYY-MM-DD') {
  const date = dateString ? new Date(dateString + 'T23:59:59') : new Date();
  return moment(date).format(pattern);
}

export function getDecimalFormat(value, forceFloat = false) {
  if (value === null || value === undefined) {
    return '';
  }
  const isFloat = forceFloat || value > Number.parseInt(value / 100 * 100);
	const options = {
		minimumFractionDigits: isFloat ? 2 : 0,
		maximumFractionDigits: isFloat ? 2 : 0
	};
	return Number(value).toLocaleString('en', options);
}

export function getRoundValue(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function getPriceFormat(price, forceFloat = false) {
	return '$' + getDecimalFormat(getRoundValue(price), forceFloat);
}

export function getStatusString(status) {
  if (status === 101) {
    return 'Placed';
  } else if (status === 102) {
    return 'Scheduled';
  } else if (status === 110) {
    return 'Completed';
  } else {
    return '';
  }
}

export function getTimeString(time) {
  if (!time || time < 8 || time > 17) {
    return '';
  } else if (time < 12) {
    return `${time}am`;
  } else if (time == 12) {
    return '12pm';
  } else {
    return `${time - 12}pm`;
  }
}

// loader: spinner, dots, bars
export function showLoading(self, type = 0) {
  const loader = self.$loading.show({
    loader: type === 1 ? 'dots' : (type === 2 ? 'bars' : 'spinner'),
    backgroundColor: '#636F83',
    color: '#EB4C60'
  });
  return loader;
}